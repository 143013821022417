<template>
    <nav v-if="product !== null" class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/products" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/products/list" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Lista produktów</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Edycja produktu</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/products/list" class="block md:hidden transition ease-in-out duration-300 text-sm mb-2 font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

    <div v-if="product !== null" class="flex items-center justify-end md:mt-4 mb-4">
        <button type="button" @click.prevent="deleteAsk = true" class="transition ease-in-out duration-300 inline-flex items-center justify-center w-full sm:w-auto sm-w rounded border border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń produkt</button>
    </div>

    <askForDelete v-if="deleteAsk" @accept="deleteProductFromDatabase" :modalData="modalData" @closeModal="deleteAsk = false"></askForDelete>


    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-if="step === 1 && product !== null">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>
                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Nazwa produktu<span class="text-red-500">*</span> </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" maxlength="60" v-model="product.name" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Zdjęcie </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2" >
                            <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                <img :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full">
                            </div>
                            <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                            </div>
                            
                            
                            <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent @click="triggerImgClick">
                                <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                    <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                    <span>Wgraj zdjęcie</span>
                                    <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                    </label>
                                    <p class="pl-1">lub przeciągnij je tutaj</p>
                                </div>
                                <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                </div>
                            </div>
                            <span @click.prevent="toggleOtherSourcePhoto" class="text-xs text-blue-500 select-none hover:text-blue-700 cursor-pointer">{{showImgInputField === true ? "Cofnij wpisywanie" : "Kliknij, aby podać link z innego źródła.."}}</span>
                            <div v-if="showImgInputField" class="mt-2 w-full flex items-center">
                                <div class="mt-1 border-b border-gray-300 focus-within:border-blue-600 w-1/2">
                                    <input type="text" v-model="imgExternalInputValue" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" placeholder="URL obrazka" />
                                </div>
                                <div class="ml-2" v-if="this.imgExternalInputValue.length > 0">
                                  <button type="button" @click.prevent="replaceImgWithExternalLink" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zapisz</button>
                                </div>
                            </div>                        
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Kategoria</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <select @change="setCategory" v-model="setOptions.category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                                    <option value="">Brak</option>
                                    <option v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex">{{category.name}} - (ID: {{category.id}})</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Producent</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div v-if="!product.selfProduction" class="max-w-lg flex rounded-md shadow-sm">
                                <input v-if="typeof product.manufacturer === 'string'" type="text" maxlength="40" v-model="product.manufacturer" :disabled="product.selfProduction" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                <input v-if="typeof product.manufacturer === 'object'" type="text" maxlength="40" v-model="product.manufacturer.name" :disabled="product.selfProduction" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="product.selfProduction" class="py-1.5">
                                <span class="py-2  sm:text-sm">{{product.manufacturer.name}}</span>
                            </div>
                            
                            <fieldset class="mt-2">
                                <legend class="sr-only">Produkcja własna</legend>
                                <div class="relative flex items-start">
                                    <div class="flex h-5 items-center">
                                        <input id="selfProduction" @change="changeManufacturer()" v-model="product.selfProduction" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer" />
                                    </div>
                                    <div class="ml-2 text-sm">
                                        <label for="selfProduction" class="text-gray-700 cursor-pointer select-none">Produkcja własna</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="40" v-model="product.model" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Wymiary</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Długość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkLength" name="dimension" v-model="product.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkHeight" name="dimension" v-model="product.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkWidth" name="dimension" v-model="product.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Waga</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkWeight" name="dimension" v-model="product.weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość paczek</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="fixAmountOfPackages()" name="amountOfPackages" v-model="product.amountOfPackages" id="dimension" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość paczek" aria-describedby="amountOfPackages" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="product.parts === undefined && parts.length === 0" class="mt-2 max-w-lg sm:gap-4 flex justify-end"> -->
                                <!-- <button type="button" @click.prevent="createPackageNames()" class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"> -->
                                    <!-- <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" /> -->
                                    <!-- Dodaj nazwy elementów -->
                                <!-- </button> -->
                            <!-- </div> -->
                        </div>
                    </div>


                        <!-- <label v-if="parts.length > 0" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nazwy paczek</label>
                        <div v-if="parts.length > 0" class="mt-1 pt-2 sm:mt-0 sm:col-span-2 max-w-lg">
                            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-2">
                                <div v-for="(prod, indx) in parts" :key="indx" :class="['relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm hover:border-gray-400']">
                                    <div class="relative min-w-0 flex-1">
                                        <label for="name" class="absolute -top-2 left-2 inline-block bg-gradient-to-b from-gray-100 px-1 text-xs font-medium text-gray-900">{{indx+1}}</label>
                                        <input type="text" name="packages" v-model="parts[indx].name" id="parts" class="transition-all duration-300 flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Nazwa elementu" aria-describedby="packages" />
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <label v-if="product.parts !== undefined && product.parts !== null && Object.keys(product.parts).length > 0" class="block text-sm font-medium text-gray-700 mt-4 sm:mt-px sm:pt-2">Nazwy paczek</label>
                        <div v-if="product.parts !== undefined && product.parts !== null && Object.keys(product.parts).length > 0" class="mt-1 pt-2 sm:mt-0 sm:col-span-2 max-w-lg">
                            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-2">
                                <div v-for="(prod, indx) in product.parts" :key="indx" :class="['relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm hover:border-gray-400']">
                                    <div class="relative min-w-0 flex-1">
                                        <label for="name" class="absolute -top-2 left-2 inline-block bg-gradient-to-b from-gray-100 px-1 text-xs font-medium text-gray-900">{{indx}}</label>
                                        <input type="text" name="packages" v-model="product.parts[indx].name" id="parts" class="transition-all duration-300 flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Nazwa elementu" aria-describedby="packages" />
                                    </div>
                                </div>
                            </div>
                        </div> -->



                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> EAN</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.ean" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> SKU</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.sku" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Opis produktu </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea v-model="product.description" rows="3" class="max-w-lg py-2 px-4 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 1</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.extraField1" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 2</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField2" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 3</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField3" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>



                </div>
            </div>            


        </div>

        <div class="pt-5">
            <div class="flex flex-col sm:flex-row justify-end">
                <button type="submit" @click.prevent="changeStep(2)" :class="{'transition ease-in-out duration-300 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
            </div>
        </div>
    </form>

    



    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-if="step === 2 && product !== null">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Informacje o lokalizacji</p>
                </div>
                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Położenie</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="40" v-model="product.storage.location" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dodatkowe informacje</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="65" v-model="product.storage.additionalInfo" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-12">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Zewnętrzne źródła</p>
                </div>

                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Link</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.link.href" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">Uzupełnienie tego pola umożliwi szybki dostęp do tego produktu. </p>
                            <p class="mt-2 text-xs text-gray-500 italic">Przykładowo: </p>
                            <p class="text-xs text-gray-500 italic">https://jan-art.sklep.pl/produkt?id=12345</p>
                            
                        </div>
                    </div>


                </div>


            </div>            
        </div>

        <div class="pt-5">
        <div class="flex flex-col sm:flex-row justify-end">
            <button type="button" @click.prevent="changeStep(1)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
            <button type="submit" @click.prevent="changeStep(3)" :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
        </div>
        </div>
    </form>

    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-if="step === 3 && product !== null">
        
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="mt-5">
                <div :class="['flex justify-between items-center', product.attribs.list.length>0 ? 'border-b border-gray-200 pb-4' : '']">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
                    <button @click.prevent="showModal = true" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <PlusSmIconOutline class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6">
                    <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                        <li v-for="(attrib,atrbIndx) in product.attribs.list" :key="attrib.id" :class="[atrbIndx < product.attribs.list.length -1 ? 'py-4' : 'pt-4 pb-2']">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                                    <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm"> {{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        ID: {{ attrib.id }}
                                    </p>
                                </div>
                                <div>
                                    <button class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200" @click.prevent="product.attribs.list.splice(atrbIndx,1)"> Usuń </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
                </div>


            </div>
       
        </div>

        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="mt-12">
                <div :class="['flex justify-between items-center', product.attribs.list.length>0 ? 'border-b border-gray-200 pb-4' : '']">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Warianty</p>
                    <button @click.prevent="showModalVariant = true" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <PlusSmIconOutline class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6">
                    <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="Object.keys(product.variants).length>0">
                        <li v-for="(variant,variantIndx) in product.variants" :key="variantIndx" :class="[variantIndx !== Object.keys(product.variants)[Object.keys(product.variants).length -1] ? 'py-4' : 'pt-4 pb-2']">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <img v-if="variant.image.url.length > 0 || variant.imagePreview !== undefined" class="h-10 w-10 rounded-full" :src="variant.image.url.length > 0 ? variant.image.url : variant.imagePreview" alt="" />
                                    <div v-else class="h-10 w-10 rounded-full text-white bg-yellow-400 flex justify-center items-center text-sm">{{variant.name.substring(0,3).toUpperCase()}}</div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        {{ variant.name }} 
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        ID: {{ variantIndx }}
                                    </p>
                                </div>
                                <div class="w-16">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        Ilość:
                                    </p>
                                    <p :class="['text-sm truncate', variant.quantity < 1 ? 'text-red-500' : (variant.quantity > 0 && variant.quantity < 4) ? 'text-yellow-500' :  'text-gray-500']">
                                        {{variant.quantity}} szt.
                                    </p>
                                </div>
                                <div>
                                    <button @click.prevent="openEditVariant(variant)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-yellow-50 hover:text-yellow-500 hover:border-yellow-200"> Edytuj </button>
                                    <button @click.prevent="openViewVariant(variant)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 ml-2 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-blue-50 hover:text-blue-500 hover:border-blue-200"> Zobacz </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <EmptyState v-if="Object.keys(product.variants).length < 1"></EmptyState>
                </div>

            </div>
       
        </div>

        <div class="pt-5">
            <div class="flex flex-col sm:flex-row justify-end">
                <button type="button" @click.prevent="changeStep(2)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                <button type="submit" @click.prevent="changeStep(4)" :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Aktualizuj produkt</button>
            </div>
        </div>
    </form>
    <AttribListModal :show="showModal" @close="closeModal" @addSelectedOptions="pushToAttribList"></AttribListModal>
    <VariantAddModal v-if="showModalVariant" @closeModal="closeVariantModal" @addVariant="addVariant" :product="product" :optionsMain="options" @addSelectedOptions="pushToAttribList"></VariantAddModal>
    <VariantViewModal v-if="viewVariantModal.open" :product="viewVariantModal.variant" @editVariant="changeToEditVariant" @close="closeViewVariant"></VariantViewModal>
    <VariantEditModal v-if="editVariantModal.open" :product="editVariantModal.variant" @editVariant="editVariant" @closeModal="closeEditVariant" :optionsMain="options" @removeVariant="removeVariant"></VariantEditModal>

</template>

<script>
    import randomstring from 'randomstring';
    import moment from 'moment';
    import axios from 'axios';
    import { PlusSmIcon as PlusSmIconOutline, PlusCircleIcon } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import {db,storage} from "@/firebase/gfbconf.js";
    import AttribListModal from '@/components/AttribListModal.vue';
    import VariantAddModal from '@/components/VariantAddModal.vue';
    import VariantViewModal from '@/components/VariantViewModal.vue';
    import VariantEditModal from '@/components/VariantEditModal.vue';
    import EmptyState from '../components/EmptyState.vue';
    import askForDelete from '@/components/AlertModal.vue';

    export default {
        name: "EditProduct",
        data()
        {
            return {
                parts: [],
                imgExternalInputValue: "",
                showImgInputField: false,
                integratorIdsBeforeSave: {
                    bol: [],
                    wayfair: [],
                    hood: [],
                },
                variantsRemoveOnSave: [],
                newMainImage: null,
                deleteAsk: false,
                fileLimitSize: 2097152,
                showModal: false,
                showModalVariant: false,
                setOptions: {
                    category: "",
                },
                viewVariantModal: {
                    open: false,
                    variant: null,
                },
                editVariantModal: {
                    open: false,
                    variant: null,
                },
                photoToDeletePath: "",
                imgPreviewSrc: "",
                step: 1,
                options: {
                    category: [],
                },
                modalData: {
                    headerText: "Usuwanie produktu",
                    bodyText: "Czy na pewno chcesz usunąć ten produkt wraz z wariantami? Dane zostaną permamentnie usunięte.",
                    acceptButtonText: "Usuń"
                },
                product: null
            }
        },
        components: {
            PlusSmIconOutline,
            AttribListModal,
            EmptyState,
            askForDelete,
            HomeIcon,
            VariantAddModal,
            VariantViewModal,
            VariantEditModal,
            PlusCircleIcon
        },
        async created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
            await this.getProductById();
            if(this.$route.query.step){
                this.step = parseInt(this.$route.query.step)
                this.$router.push(`/dashboard/products/list/editProduct?id=${this.$route.query.id}`)
            }
        },
        methods:
        {
            createPackageNames()
            {
                for(let i=0; i<this.product.amountOfPackages; i++)
                {
                    this.parts.push({
                        name: '',
                        quantity: 0
                    })
                }
            },
            replaceImgWithExternalLink()
            {
                if(this.imgExternalInputValue.length === 0) return;
                this.clearImg();
                this.product.image.ref = null;
                this.product.image.url = this.imgExternalInputValue;
                this.imgPreviewSrc = this.imgExternalInputValue;
                this.imgExternalInputValue = "";
                this.showImgInputField = false;
            },
            toggleOtherSourcePhoto()
            {
                this.clearImg();
                this.product.image.ref = null;
                this.product.image.url = "";
                this.showImgInputField = !this.showImgInputField;
            },
            fixAmountOfPackages(){
                this.parts = [];
                this.product.amountOfPackages = Math.ceil(this.product.amountOfPackages)
                if(this.product.amountOfPackages < 1){
                    this.product.amountOfPackages = 1
                }
                if(this.product.amountOfPackages > 100){
                    this.product.amountOfPackages = 100
                }
                this.product.amountOfPackages = parseInt(this.product.amountOfPackages)
            },
            changeToEditVariant(id){
                this.closeViewVariant()
                this.openEditVariant(this.product.variants[id])
            },
            editVariant(variant){
                const editedVariantId = variant.id
                this.product.variants[editedVariantId] = variant
                this.closeEditVariant()
            },
            removeVariant(id){
                this.variantsRemoveOnSave.push({
                    image: {
                        ref: this.product.variants[id].image.ref
                    },
                    id: this.product.variants[id].id
                });
                delete this.product.variants[id]
                this.closeEditVariant()
            },
            closeEditVariant()
            {
                this.editVariantModal = {
                    open: false,
                    variant: null
                }
            },
            openEditVariant(variant){
                this.editVariantModal = {
                    open: true,
                    variant: variant
                }
            },
            closeViewVariant()
            {
                this.viewVariantModal = {
                    open: false,
                    variant: null
                }
            },
            openViewVariant(variant){
                this.viewVariantModal = {
                    open: true,
                    variant: variant
                }
            },
            addVariant(variant){
                this.product.variants[variant.id] = variant
            },
            changeManufacturer(){
                if(this.product.selfProduction)
                {
                    this.product.manufacturer.name = "Jan-Art Sp. z o.o."
                }
                else
                {
                    if(typeof this.product.manufacturer === 'string')
                    {
                        if(this.product.manufacturer.length > 0)
                        {
                            this.product.manufacturer.name = this.product.manufacturer
                        }
                        else
                        {
                            this.product.manufacturer = '';
                        }
                    }
                    else
                    {
                        if(this.product.manufacturer.name.length > 0)
                        {
                            this.product.manufacturer.name = this.product.manufacturer.name
                        }
                        else
                        {
                            this.product.manufacturer = '';
                        }
                    }
                };
            },
            prepareElasticSearchDocument(product)
            {
                let names = [],skus = [],eans = [],categories = [],manufacturers = [],blIds = [], apiloIds = [], variantIds = [] ,models = [];

                names.push(product.name);

                if(product.baselinkerId == null) product.baselinkerId = '';

                if(product.sku.length>0) skus.push(product.sku);
                if(product.ean.length>0) eans.push(product.ean);
                if(product.category.name.length>0) categories.push(product.category.name);
                if(typeof product.manufacturer === 'string' ? product.manufacturer.length>0 : product.manufacturer.name.length>0) manufacturers.push(typeof product.manufacturer === 'string' ? product.manufacturer : product.manufacturer.name);
                if(product.baselinkerId.toString().length>0) blIds.push(product.baselinkerId.toString());
                if(product.model.length>0) models.push(product.model);

                for(let vrnt in product.variants)
                {
                    let variant = product.variants[vrnt];
                    if(!names.includes(variant.name)) names.push(variant.name);
                    if(!skus.includes(variant.sku) && variant.sku.length>0) skus.push(variant.sku);
                    if(!eans.includes(variant.ean) && variant.ean.length>0) eans.push(variant.ean);
                    if(!categories.includes(variant.category.name) && variant.category.name.length>0) categories.push(variant.category.name);
                    if(!manufacturers.includes(typeof variant.manufacturer === 'string' ? variant.manufacturer : variant.manufacturer.name) && (typeof variant.manufacturer === 'string' ? variant.manufacturer.length>0 : variant.manufacturer.name.length>0)) manufacturers.push(typeof variant.manufacturer === 'string' ? variant.manufacturer : variant.manufacturer.name);
                    if(variant.baselinkerId !== undefined && !blIds.includes(variant.baselinkerId.toString())) blIds.push(variant.baselinkerId.toString());
                    if(variant.apiloId !== undefined && !apiloIds.includes(variant.apiloId.toString())) apiloIds.push(variant.apiloId.toString());
                    if(!variantIds.includes(variant.id)) variantIds.push(variant.id);
                    if(!models.includes(variant.model) && variant.model.length>0) models.push(variant.model);
                }

                let document = {
                    names: names,
                    models: models,
                    skus: skus,
                    eans: eans,
                    id: product.id,
                    categories: categories,
                    manufacturers: manufacturers,
                    variantsIds: variantIds,
                    baselinkerIds: blIds,
                    apiloIds: apiloIds
                }

                return document;
            },
            async deleteProductFromDatabase()
            {
                try{
                    this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Usuwanie produktu..",
                        subheader: `Nie zamykaj tego okna.`,
                    });

                    // Delete images from storage
                    if(this.product.image.url.length>0)
                    {
                        if(this.product.image.ref !== null)
                        {
                            const mainImageRef = storage.ref().child(this.product.image.ref);
                            await mainImageRef.delete();
                        }
                    }
                    if(Object.keys(this.product.variants).length > 0){
                        for(let i=0; i<Object.keys(this.product.variants).length; i++)
                        {
                            let currentId = Object.keys(this.product.variants)[i];
                            await db.collection("WayfairIntegrator").doc(currentId.toString()).delete();
                            await db.collection("BolIntegrator").doc(currentId.toString()).delete();
                            await db.collection("HoodIntegrator").doc(currentId.toString()).delete();
                            if(this.product.variants[currentId].image.url.length > 0){
                                if(this.product.variants[currentId].image.ref !== null)
                                {
                                    const mainImageRef = storage.ref().child(this.product.variants[currentId].image.ref);
                                    await mainImageRef.delete();
                                }
                            }
                        }
                    }
                    // Delete doc from main db
                    await db.collection("Products").doc(this.$route.query.id).delete();

                    if(this.product.baselinkerId !== undefined)
                    {
                        await axios.post(`${this.$store.state.apiLink}/baselinker/product/delete`, {
                            whoAmI: this.$store.state.userData.id,
                            id: this.product.baselinkerId
                        }).catch((error)=>
                        {
                            console.log(error);
                        })
                    }

                    if(this.product.elasticSearch.docId.length>0)
                    {
                        const res = await axios.post(`${this.$store.state.apiLink}/elastic/delete`, {
                            index: "jan-art-products",
                            id: this.product.elasticSearch.docId
                        }).catch((error)=>
                        {
                            console.log(error);
                        })
                    }


                    // Notify user
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Produkt został usunięty!",
                        subheader: ``,
                        success: true
                    });
                    this.$store.commit('setGlobalLoader',{
                        show: false,
                        head: "Usuwanie produktu..",
                        subheader: `Nie zamykaj tego okna.`,
                    });           
                    this.$router.push("/dashboard/products/list");
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setGlobalLoader',{
                        show: false,
                        head: "Usuwanie produktu..",
                        subheader: `Nie zamykaj tego okna.`,
                    });
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Coś poszło nie tak!",
                        subheader: ``,
                        success: false
                    });
                    this.$router.push("/dashboard/products/list");
                }

            },
            async updateProduct()
            {
                // update
                if(!this.$route.query.id) this.$router.push("/dashboard/products/list");
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Aktualizacja produktu..",
                    subheader: `Nie zamykaj tego okna.`,
                });


                // delete old main img from storage
                if(this.photoToDeletePath !== null)
                {
                    
                    if(this.photoToDeletePath.length > 0)
                    {
                        const mainImageRef = storage.ref().child(this.photoToDeletePath);
                        await mainImageRef.delete().catch((error) =>
                        {
                            console.log(error);
                        });
                        this.product.image.ref = null;
                        this.product.image.url = "";
                        this.photoToDeletePath = "" 
                    }

                }
                else
                {
                    this.product.image.ref = null;
                    // this.product.image.url = "";
                    this.photoToDeletePath = ""
                }


                // upload new main img
                if(this.newMainImage !== null)
                {
                    const storageRef = storage.ref();
                    const filename = this.filenameGenerator(10);
                    const path = `products/${filename}`;
                    const imageRef = storageRef.child(path);
                    await imageRef.put(this.newMainImage);
                    const fileUrl = await imageRef.getDownloadURL();
                    this.product.image.url = fileUrl;
                    this.product.image.ref = path;
                }


                if(Object.keys(this.product.variants).length > 0){
                    for(let i=0; i<Object.keys(this.product.variants).length; i++)
                    {
                        let currentId = Object.keys(this.product.variants)[i]
                        if(this.product.variants[currentId].imageToUpload !== undefined){
                            const storageRef = storage.ref();
                            const filename = this.filenameGenerator(10);
                            const path = `products/${filename + 'v_' + `${currentId}`}`;
                            const imageRef = storageRef.child(path);
                            await imageRef.put(this.product.variants[currentId].imageToUpload);
                            const fileUrl = await imageRef.getDownloadURL();
                            this.product.variants[currentId].image.url = fileUrl;
                            this.product.variants[currentId].image.ref = path;
                            delete this.product.variants[currentId].imageToUpload
                            delete this.product.variants[currentId].imagePreview
                        }
                        if(this.product.variants[currentId].imageToDelete !== undefined){
                            const mainImageRef = storage.ref().child(this.product.variants[currentId].imageToDelete);
                            await mainImageRef.delete().catch((error) =>
                            {
                                console.log(error);
                            });
                            delete this.product.variants[currentId].imageToDelete
                        }

                        if(this.product.variants[currentId].parts != null)
                        {

                            // let totalPartsAmount = 0;
                            // let productEntries = Object.entries(this.product.variants[currentId].parts);
                            // let numberToCheck = null;
                            // let lowestNumber = null;
                            // if(productEntries.length == this.product.variants[currentId].amountOfPackages)
                            // {
                            //     let everythingSame = true;
                            //     for(let i=0; i<productEntries.length; i++)
                            //     {
                            //         if(productEntries[i][1].quantity < 0) productEntries[i][1].quantity = 0;
                            //         if(productEntries[i][1].quantity < lowestNumber || lowestNumber === null)
                            //         {
                            //             lowestNumber = productEntries[i][1].quantity;
                            //         }
                            //         if(numberToCheck === null)
                            //         {
                            //             numberToCheck = productEntries[i][1].quantity;
                            //         }
                            //         if(numberToCheck !== productEntries[i][1].quantity)
                            //         {
                            //             everythingSame = false;
                            //         }
                            //     }
                            //     if(everythingSame)
                            //     {
                            //         totalPartsAmount = numberToCheck;
                            //         this.product.variants[currentId].quantity = totalPartsAmount;
                            //     }
                            //     else
                            //     {
                            //         totalPartsAmount = lowestNumber;
                            //         this.product.variants[currentId].quantity = totalPartsAmount;
                            //     }
                            // }

                            // this.product.variants[currentId].parts

                            let products = {};
                            let segmentsToCheck = [];
                            let backlogRef = db.collection('StorageBacklogs').doc();
                            const getStorageToCheck = await db.collection('Storage').get();
                            for(let i=0; i<getStorageToCheck.docs.length; i++)
                            {
                                let current = getStorageToCheck.docs[i].data();
                                const getSegmentsToCheck = await db.collection('Storage').doc(current.id.toString()).collection('Segments').get();
                                for(let y=0; y<getSegmentsToCheck.docs.length; y++)
                                {
                                    let crnt = getSegmentsToCheck.docs[y].data();
                                    crnt.warehouse = current;
                                    if(Object.keys(crnt.products).length > 0)
                                    {
                                        segmentsToCheck.push(crnt)
                                    }
                                }
                            }
            
                            if(segmentsToCheck.length === 0) throw 'BRAK SEGMENTÓW.'

                            if(this.product.variants[currentId].parts == null) throw 'BRAK ZESKANOWANYCH PACZEK W PRODUKCIE';
                            const partsToCheck = Object.entries(this.product.variants[currentId].parts);
                            for (let i = 0; i < partsToCheck.length; i++) {
                                partsToCheck[i][1].quantity = 0;
                                let partFound = false;
                                for (let y = 0; y < segmentsToCheck.length; y++) {
                                    if (partFound) break;
                                    if (segmentsToCheck[y].products[this.product.id] != null) {
                                        const addedToSegment = Object.entries(segmentsToCheck[y].products[this.product.id]);
                                        for (let a = 0; a < addedToSegment.length; a++) {
                                            if (addedToSegment[a][0].includes(currentId)) {
                                                let str = addedToSegment[a][0];
                                                let num = str.lastIndexOf('_');
                                                let res = str.substring(num + 1);
                                                if (partsToCheck[i][1].name === res) {
                                                    partsToCheck[i][1].quantity += addedToSegment[a][1].quantity;
                                                    if (addedToSegment[a][1].order != null && addedToSegment[a][1].order.length > 0) {
                                                        partsToCheck[i][1].quantity -= addedToSegment[a][1].order.length;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                this.product.variants[currentId].parts[partsToCheck[i][0]].quantity = partsToCheck[i][1].quantity;
                            }

                            let totalPartsAmount = 0;
                            let productEntries = Object.entries(this.product.variants[currentId].parts);
                            let numberToCheck = null;
                            let lowestNumber = null;
                            if(productEntries.length === this.product.variants[currentId].amountOfPackages)
                            {
                                let everythingSame = true;
                                for(let i=0; i<productEntries.length; i++)
                                {
                                    if(productEntries[i][1].quantity < lowestNumber || lowestNumber === null)
                                    {
                                        lowestNumber = productEntries[i][1].quantity;
                                    }
                                    if(numberToCheck === null)
                                    {
                                        numberToCheck = productEntries[i][1].quantity;
                                    }
                                    if(numberToCheck !== productEntries[i][1].quantity)
                                    {
                                        everythingSame = false;
                                    }
                                }
                                if(everythingSame)
                                {
                                    totalPartsAmount = numberToCheck;
                                    // position.product.quantity = numberToCheck;
                                    this.product.variants[currentId].quantity = totalPartsAmount;
                                }
                                else
                                {
                                    totalPartsAmount = lowestNumber;
                                    // position.product.quantity = lowestNumber;
                                    this.product.variants[currentId].quantity = totalPartsAmount
                                }
                            };

                        }
                    }
                }

                let packages = {};
                if(this.product.amountOfPackages > 0)
                {
                    if(this.parts.length > 0)
                    {
                        for(let i=0; i<this.parts.length; i++)
                        {
                            packages[i+1] = {
                                name: this.parts[i].name,
                                quantity: 0
                            }
                        }
                    }
                    if(this.product.parts !== undefined && Object.keys(this.product.parts).length > 0)
                    {
                        for(let i=0; i<Object.keys(this.product.parts).length; i++)
                        {
                            let currentId = Object.keys(this.product.parts)[i]
                            packages[i+1] = {
                                name: this.product.parts[currentId].name,
                                quantity: 0
                            }
                        }
                    }
                    this.product.parts = packages
                }            
    
                this.product.dimensions.height = parseFloat(this.product.dimensions.height);
                this.product.dimensions.width = parseFloat(this.product.dimensions.width);
                this.product.dimensions.length = parseFloat(this.product.dimensions.length);
                this.product.weight.amount = parseFloat(this.product.weight.amount);
                this.product.meta.lastModificationDate = moment().toDate();

                // Remove variants data from db and storage
                if(this.variantsRemoveOnSave.length > 0)
                {
                    for(let y=0; y<this.variantsRemoveOnSave.length;y++)
                    {
                        let cur = this.variantsRemoveOnSave[y];
                        if(cur.image.ref !== null)
                        {
                            await storage.ref().child(cur.image.ref).delete();
                        }
                        
                        await db.collection("WayfairIntegrator").doc(cur.id.toString()).delete();
                        await db.collection("BolIntegrator").doc(cur.id.toString()).delete();
                        await db.collection("HoodIntegrator").doc(cur.id.toString()).delete();
                    }
                }
                this.variantsRemoveOnSave = [];
                // Add to Db
                await db.collection("Products").doc(this.$route.query.id).update(this.product).catch((error)=>
                {
                    console.log(error);
                    this.$router.push("/dashboard/products/list");
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie mogę zaktualizować produktu.",
                        subheader: `Wystąpił błąd podcczas edycji.`,
                        success: false
                    }); 
                });

                for(let vr in this.product.variants)
                {
                    let vari = this.product.variants[vr];
                    // UpdateController
                    let needUpdateBol = false;
                    let needUpdateWayfair = false;
                    let needUpdateHood = false;
                    let bolFound = false;
                    let wayfairFound = false;
                    let hoodFound = false;

                    // Bol
                    for(let i=0; i < this.integratorIdsBeforeSave.bol.length; i++)
                    {
                        let currBol = this.integratorIdsBeforeSave.bol[i];
                        if(currBol.variantId === vari.id)
                        {
                            bolFound = true;
                            if(currBol.integratorId !== vari.integratorIds.bol)
                            {
                                needUpdateBol = true;
                            }
                        }
                    }

                    if(!bolFound)
                    {
                        needUpdateBol = true;
                    }

                    // Wayfair
                    for(let i=0; i < this.integratorIdsBeforeSave.wayfair.length; i++)
                    {
                        let curr = this.integratorIdsBeforeSave.wayfair[i];
                        if(curr.variantId === vari.id)
                        {
                            wayfairFound = true;
                            if(curr.integratorId !== vari.integratorIds.wayfair)
                            {
                                needUpdateWayfair = true;
                            }
                        }
                    }

                    if(!wayfairFound)
                    {
                        needUpdateWayfair = true;
                    }

                    // Hood
                    for(let i=0; i < this.integratorIdsBeforeSave.hood.length; i++)
                    {
                        let curr = this.integratorIdsBeforeSave.hood[i];
                        if(curr.variantId === vari.id)
                        {
                            hoodFound = true;
                            if(curr.integratorId !== vari.integratorIds.hood)
                            {
                                needUpdateHood = true;
                            }
                        }
                    }

                    if(!hoodFound)
                    {
                        needUpdateHood = true;
                    }

                    if(needUpdateHood)
                    {
                        await db.collection("HoodIntegrator").doc(vari.id.toString()).set({
                            type: "Hood",
                            variantId: vari.id,
                            productId: this.product.id,
                            integratorId: vari.integratorIds.hood,
                            ref: db.collection("Products").doc(this.product.id.toString())                         
                        })
                    }

                    if(needUpdateWayfair)
                    {
                        await db.collection("WayfairIntegrator").doc(vari.id.toString()).set({
                            type: "Wayfair",
                            variantId: vari.id,
                            productId: this.product.id,
                            integratorId: vari.integratorIds.wayfair,
                            ref: db.collection("Products").doc(this.product.id.toString())                       
                        })
                    }

                    if(needUpdateBol)
                    {
                        await db.collection("BolIntegrator").doc(vari.id.toString()).set({
                            type: "Bol",
                            variantId: vari.id,
                            productId: this.product.id,
                            integratorId: vari.integratorIds.bol,
                            ref: db.collection("Products").doc(this.product.id.toString())                       
                        })
                    }
                }

                // const apiloRes = await axios.post(`${this.$store.state.herokuApiLink}/marketplace/apilo/${this.$store.state.herokuApiPath}/product/create-product`, {
                //     product: this.product,
                // }, {
                //     timeout: 20000,
                //     headers: {
                //         'x-api-key': process.env.VUE_APP_APIKEY,
                //         'x-api-jwt-token': this.$store.state.userData.jwt,
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json',
                //     },
                // })

                // if(apiloRes.data.success)
                // {
                //     for(let vrnt in this.product.variants)
                //     {
                //         let variant = this.product.variants[vrnt];
                //         if(variant.apiloId === undefined)
                //         {
                //             if(apiloRes.data.payload.data[variant.id] !== undefined)
                //             {
                //                 variant.apiloId = apiloRes.data.payload.data[variant.id];
                //             }
                //         }
                //     }
                // }

                let baselinkerRes = await axios.post(`${this.$store.state.apiLink}/baselinker/product/add`, {
                    whoAmI: this.$store.state.userData.id,
                    product: this.product
                })

                if(baselinkerRes.data.success)
                {
                    for(let vrnt in this.product.variants)
                    {
                        let variant = this.product.variants[vrnt];
                        if(variant.baselinkerId === undefined)
                        {
                            if(baselinkerRes.data.ids[variant.id] !== undefined)
                            {
                                variant.baselinkerId = baselinkerRes.data.ids[variant.id];
                            }
                        }
                    }
                }
                else
                {
                    for(let vrnt in this.product.variants)
                    {
                        let variant = this.product.variants[vrnt];
                        if(variant.baselinkerId === undefined)
                        {
                            variant.baselinkerId = '';
                        }
                    }
                }
                
                if(this.product.elasticSearch.docId.length>0)
                {
                    let document = this.prepareElasticSearchDocument(this.product);
                    await axios.post(`${this.$store.state.apiLink}/elastic/update`, {
                        index: "jan-art-products",
                        elasticId: this.product.elasticSearch.docId,
                        document: document
                    })
                }

                // Notify user
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Aktualizacja przebiegła pomyślnie!",
                    subheader: `Zmiany zostały wprowadzone do produktu.`,
                    success: true
                });

                this.$store.commit('setGlobalLoader',{
                    show: false,
                    head: "Aktualizacja produktu..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                this.$router.push(`/dashboard/products/list/viewProduct?id=${this.$route.query.id}`);
            },
            async getProductById()
            {
                if(!this.$route.query.id) this.$router.push("/dashboard/products/list");
                // Get actual product
                const productGetResult = await db.collection("Products").doc(this.$route.query.id).get();
                if(!productGetResult.exists) this.$router.push("/dashboard/products/list");
                this.product = productGetResult.data();
                document.title = `Edycja ${this.product.name} - Jan-Art Sp. z o.o.`
                /***** Set manufactuer,category,warehouse field by actual product value. *****/
                //Category data
                const categoryDocsResult = await db.collection("Categories").get();
                for(let i=0; i<categoryDocsResult.docs.length; i++)
                {
                    const catData = categoryDocsResult.docs[i].data();
                    this.options.category.push(catData);
                    if(this.product.category.id.toString() === catData.id.toString())
                    {
                        this.setOptions.category = i;
                    }
                }
                // Add Integrator IDs for checker
                for(let id in this.product.variants)
                {
                    let vari = this.product.variants[id];
                    this.integratorIdsBeforeSave.bol.push({
                        type: "Bol",
                        variantId: vari.id,
                        productId: this.product.id,
                        integratorId: vari.integratorIds.bol,
                        ref: db.collection("Products").doc(this.product.id.toString())
                    })

                    this.integratorIdsBeforeSave.wayfair.push({
                        type: "Wayfair",
                        variantId: vari.id,
                        productId: this.product.id,
                        integratorId: vari.integratorIds.wayfair,
                        ref: db.collection("Products").doc(this.product.id.toString())
                    })

                    this.integratorIdsBeforeSave.hood.push({
                        type: "Hood",
                        variantId: vari.id,
                        productId: this.product.id,
                        integratorId: vari.integratorIds.hood,
                        ref: db.collection("Products").doc(this.product.id.toString())
                    })
                }

                if(typeof this.product.manufacturer === 'string')
                {
                    this.product.manufacturer = {
                        name: this.product.manufacturer,
                    };
                }


                // This must stay here to clear deleted categories/manufactuers/warehouses if they were deleted before. If exsist will set good data again.
                this.setCategory();
                // Set preview of image
                this.imgPreviewSrc = this.product.image.url;
                this.allToFixed2();
            },
            pushToAttribList(buffer)
            {
                for(let i=0; i<buffer.length; i++)
                {
                    this.product.attribs.list.push(buffer[i]);
                }
            },
            closeModal()
            {
                this.showModal = false;
            },
            closeVariantModal()
            {
                this.showModalVariant = false;
            },
            setCategory()
            {
                let newValue = this.options.category[this.setOptions.category];
                if(newValue === "" || newValue === undefined)
                {
                    this.product.category.name = "";
                    this.product.category.ref = null;
                    this.product.category.id = "";
                }
                else
                {
                    this.product.category.name = newValue.name;
                    this.product.category.id = newValue.id.toString();
                    this.product.category.ref = db.collection("Categories").doc(newValue.id.toString());
                }
            },
            dropFile(e)
            {
                if(e.dataTransfer.files.length>0)
                {
                    if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                    {
                        if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                        {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.dataTransfer.files[0]);
                            fileReader.addEventListener("load", () =>
                            {
                                this.imgPreviewSrc = fileReader.result;
                            })
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Zdjęcie waży powyżej 2MB",
                                subheader: `Plik jest zbyt ciężki.`,
                                success: false
                            }); 
                        }
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Plik nie jest zdjęciem.",
                            subheader: `Plik powinien być zdjęciem.`,
                            success: false
                        });   
                    }
                }
                
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            clearImg()
            {
                this.photoToDeletePath = this.product.image.ref;
                this.product.image.ref = null;
                this.product.image.url = "";
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            allToFixed2()
            {
                this.product.dimensions.height = this.product.dimensions.height.toFixed(2);
                this.product.dimensions.width = this.product.dimensions.width.toFixed(2);
                this.product.dimensions.length = this.product.dimensions.length.toFixed(2);
                this.product.weight.amount = this.product.weight.amount.toFixed(2);
            },
            checkHeight()
            {
                if(this.product.dimensions.height.length === 0)
                {
                    this.product.dimensions.height = 0;
                }
                if(this.product.dimensions.height < 0)
                {
                    this.product.dimensions.height = 0;
                }
                else
                {
                    this.product.dimensions.height = parseFloat(this.product.dimensions.height);
                }
                this.product.dimensions.height = this.product.dimensions.height.toFixed(2);
            },
            checkWidth()
            {
                if(this.product.dimensions.width.length === 0)
                {
                    this.product.dimensions.width = 0;
                }
                if(this.product.dimensions.width < 0)
                {
                    this.product.dimensions.width = 0;
                }
                else
                {
                    this.product.dimensions.width = parseFloat(this.product.dimensions.width);
                }
                this.product.dimensions.width = this.product.dimensions.width.toFixed(2);
            },
            checkLength()
            {
                if(this.product.dimensions.length.length === 0)
                {
                    this.product.dimensions.length = 0;
                }
                if(this.product.dimensions.length < 0)
                {
                    this.product.dimensions.length = 0;
                }
                else
                {
                    this.product.dimensions.length = parseFloat(this.product.dimensions.length);
                }
                this.product.dimensions.length = this.product.dimensions.length.toFixed(2);
            },
            checkWeight()
            {
                if(this.product.weight.amount.length === 0)
                {
                    this.product.weight.amount = 0;
                }
                if(this.product.weight.amount < 0)
                {
                    this.product.weight.amount = 0;
                }
                else
                {
                    this.product.weight.amount = parseFloat(this.product.weight.amount);
                }
                this.product.weight.amount = this.product.weight.amount.toFixed(2);
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        this.photoToDeletePath = this.product.image.ref;
                        this.product.image.ref = null;
                        this.product.image.url = "";
                        this.imgPreviewSrc = '';
                        this.newMainImage = this.$refs.mainImgUploader.files[0];
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Zdjęcie waży powyżej 2MB",
                            subheader: `Plik jest zbyt ciężki.`,
                            success: false
                        });                    
                    }
                }
            },
            filenameGenerator(length)
            {
                let result           = `prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            changeStep(val)
            {
                switch(val)
                {
                    case 1: 
                        this.step = 1;
                        break;
                    case 2:
                        if(this.validation1 === true)
                        {
                            this.step = 2;
                        }
                        break;
                    case 3:
                        this.step = 3;
                        break;
                    case 4:
                        this.step = 4;
                        this.updateProduct();
                }
            }
        },
        computed:
        {
            validation1()
            {
                if(this.product.name.length>0)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>