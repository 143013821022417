<template>
  <TransitionRoot as="template" v-if="product !== null" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle w-11/12 sm:max-h-screen9/10 sm:p-6">
            <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->
            <div>
                <h4 class="sr-only">Progres</h4>
                <p class="text-gray-500 text-xs font-medium uppercase tracking-wide">Progres dodawania wariantu</p>
                <div class="mt-2" aria-hidden="true">
                <div class="bg-gray-200 rounded-full overflow-hidden">
                    <div class="h-2 transition-all duration-1000 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-600 rounded-full" :style="`width: ${progress}%`" />
                </div>
                <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-1">
                    <div class="text-blue-500">Podstawowe informacje</div>
                    <div class="text-center text-blue-500"></div>
                    <div class="text-center text-blue-500">Lokalizacja oraz stan magazynowy</div>
                    <div class="text-center text-blue-500">Koszty oraz atrybuty</div>
                    <div class="text-right text-blue-500" >Koniec</div>
                </div>
                </div>
            </div>

            <form class="space-y-8 divide-y divide-gray-200 mt-8" v-if="!loading" v-show="step === 1">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Nazwa wariantu<span class="text-red-500">*</span> </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="60" v-model="variant.name" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Symbol Subiekt GT </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.subiekt" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Bol.com ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.bol" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Hood.de ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.hood" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Wayfair.com ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.wayfair" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>



                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Zdjęcie </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                    <span @click.prevent="toggleOtherSourcePhoto" class="text-xs text-blue-500 select-none hover:text-blue-700 cursor-pointer">{{showImgInputField === true ? "Cofnij wpisywanie" : "Kliknij, aby podać link z innego źródła.."}}</span>
                                    <div v-if="showImgInputField" class="mt-2 w-full flex items-center">
                                        <div class="mt-1 border-b border-gray-300 focus-within:border-blue-600 w-1/2">
                                            <input type="text" v-model="imgExternalInputValue" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" placeholder="URL obrazka" />
                                        </div>
                                        <div class="ml-2" v-if="this.imgExternalInputValue.length > 0">
                                        <button type="button" @click.prevent="replaceImgWithExternalLink" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zapisz</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Kategoria</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <select @change="setCategory" v-model="setOptions.category" class="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                                            <option value="">Brak</option>
                                            <option v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex">{{category.name}} - (ID: {{category.id}})</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Producent</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div v-if="!variant.selfProduction" class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="40" v-model="variant.manufacturer.name" :disabled="variant.selfProduction" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <div v-if="variant.selfProduction" class="pt-1.5 pb-2">
                                        <span class="py-2  sm:text-sm">{{typeof variant.manufacturer === 'string' ? variant.manufacturer : variant.manufacturer.name}}</span>
                                    </div>
                                    
                                    <fieldset class="mt-2">
                                        <legend class="sr-only">Produkcja własna</legend>
                                        <div class="relative flex items-start">
                                            <div class="flex h-5 items-center">
                                                <input id="selfProduction" @change="changeManufacturer()" v-model="variant.selfProduction" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer" />
                                            </div>
                                            <div class="ml-2 text-sm">
                                                <label for="selfProduction" class="text-gray-700 cursor-pointer select-none">Produkcja własna</label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="40" v-model="variant.model" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Wymiary</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Długość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="checkLength" name="dimension" v-model="variant.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="checkHeight" name="dimension" v-model="variant.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="checkWidth" name="dimension" v-model="variant.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Waga</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="checkWeight" name="weight" v-model="variant.weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość paczek</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="fixAmountOfPackages()" name="amountOfPackages" v-model="variant.amountOfPackages" id="amountOfPackages" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość paczek" aria-describedby="amountOfPackages" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> EAN</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.ean" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> SKU</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.sku" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Opis wariantu </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea v-model="variant.description" rows="3" class="max-w-lg py-2 px-4 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 1</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.extraField1" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 2</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" v-model="variant.extraField2" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 3</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" v-model="variant.extraField3" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500">Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>



                        </div>
                    </div>            


                </div>

                <div class="pt-5">
                    <div class="flex flex-col-reverse sm:flex-row justify-end">
                        <button type="button" @click.prevent="this.close()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                        <button type="submit" @click.prevent="changeStep(2)" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
                    </div>
                </div>
            </form>


            <form class="space-y-8 divide-y divide-gray-200 mt-6" v-show="step === 2">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Informacje o lokalizacji</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Położenie</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="40" v-model="variant.storage.location" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dodatkowe informacje</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="65" v-model="variant.storage.additionalInfo" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="mt-12">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Stan magazynowy</p>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div>
                                        <div class="mt-1 max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="fixQuantity()" v-model="variant.quantity" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość produktów w magazynie" aria-describedby="variant-quantity" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> SZT </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-12">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Zewnętrzne źródła</p>
                        </div>

                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Link</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.link.href" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500">Uzupełnienie tego pola umożliwi szybki dostęp do tego wariantu. </p>
                                    <p class="mt-2 text-xs text-gray-500 italic">Przykładowo: </p>
                                    <p class="text-xs text-gray-500 italic">https://jan-art.sklep.pl/produkt?id=12345</p>
                                    
                                </div>
                            </div>



                        </div>


                    </div>            
                </div>

                <div class="pt-5">
                <div class="flex flex-col-reverse sm:flex-row justify-end">
                    <button type="button" @click.prevent="this.close()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                    <button type="button" @click.prevent="changeStep(1)" class="transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                    <button type="submit" @click.prevent="changeStep(3)" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
                </div>
                </div>
            </form>


            <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 3">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Koszty</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Koszty produkcji</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkProductionCost" v-model="variant.pricesAndCosts.productionCost" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                        <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> {{ variant.pricesAndCosts.currency}}</span>
                                        </div> -->
                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                                <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Sugerowana cena końcowa</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkSellPrice" name="price" v-model="variant.pricesAndCosts.sellPrice" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Sell Price" aria-describedby="price-currency" />
                                        <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> PLN </span>
                                        </div> -->

                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                                <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Koszt reklamacji</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkComplaintCost" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Complaint Cost" v-model="variant.pricesAndCosts.complaintCost" aria-describedby="price-currency" />
                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                                <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stawka VAT</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <select v-model="variant.pricesAndCosts.taxRate" class="border w-full block mt-1 pl-3 pr-10 py-2 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                                <option :value="0">0%</option>
                                                <option v-for="index in 30" :key="index" :value="parseFloat(index)">{{index}}%</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div class="mt-12">
                        <div :class="['flex justify-between items-center', variant.attribs.list.length>0 ? 'border-b border-gray-200 pb-4' : '']">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
                            <button @click.prevent="showModal = true" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <PlusSmIconOutline class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="mt-6">
                            <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="variant.attribs.list.length>0">
                                <li v-for="(attrib,atrbIndx) in variant.attribs.list" :key="attrib.id" :class="[atrbIndx < variant.attribs.list.length -1 ? 'py-4' : 'pt-4 pb-2']">
                                    <div class="flex items-center space-x-4">
                                        <div class="flex-shrink-0">
                                            <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                                            <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm"> {{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                                        </div>
                                        <div class="flex-1 min-w-0">
                                            <p class="text-sm font-medium text-gray-900 truncate">
                                                <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                ID: {{ attrib.id }}
                                            </p>
                                        </div>
                                        <div>
                                            <button class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200" @click.prevent="variant.attribs.list.splice(atrbIndx,1)"> Usuń </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <EmptyState v-if="variant.attribs.list.length === 0"></EmptyState>
                        </div>


                    </div>
            
                </div>

                <div class="pt-5">
                    <div class="flex flex-col-reverse sm:flex-row justify-end">
                        <button type="button" @click.prevent="this.close()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                        <button type="button" @click.prevent="changeStep(2)" class="transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                        <button type="submit" @click.prevent="changeStep(4)" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Dodaj wariant</button>
                    <!-- @click.prevent="changeStep(4)" -->
                    </div>
                </div>
            </form>
            <AttribListModal :show="showModal" @close="closeModal" @addSelectedOptions="pushToAttribList"></AttribListModal>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
import EmptyState from '../components/EmptyState.vue';
import {db} from "@/firebase/gfbconf.js";
import AttribListModal from '@/components/AttribListModal.vue';
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
import moment from 'moment';
export default {
    data()
        {
        return {
            imgExternalInputValue: "",
            showImgInputField: false,
            loading: true,
            fileLimitSize: 2097152,
            showModal: false,
            setOptions: {
                category: "",
            },
            imgPreviewSrc: "",
            progress: 0,
            step: 1,
            options: {
                category: [],
            },
            variant: {
                name: "",
                model: "",
                integratorIds: {
                    bol: "",
                    hood: "",
                    wayfair: "",
                    subiekt: ""
                },
                active: true,
                quantity: 0,
                meta: {
                    createdBy: null,
                    createdDate: null,
                    lastModificationDate: null,
                    listOfChanges: []
                },
                ean: "",
                sku: "",
                amountOfPackages: 1,
                image: {
                    url: "",
                    ref: null
                },
                manufacturer: {
                    name: '',
                },
                selfProduction: false,
                category: {
                    name: "",
                    ref: null,
                    id: "",
                },
                extraField1: "",
                extraField2: "",
                extraField3: "",
                description: "",
                pricesAndCosts: {
                    currency: "PLN",
                    sellPrice: 0,
                    complaintCost: 0,
                    productionCost: 0,
                    taxRate: 23,
                },
                dimensions: {
                        unit: "cm",
                        height: 0,
                        width: 0,
                        length: 0
                    },
                weight: {
                        unit: "kg",
                        amount: 0
                    },
                storage: {
                    location: "",
                    additionalInfo: ""
                },
                link: {
                    href: ""
                },
                attribs: {
                    list: []
                }
            }
        }
    },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    PlusSmIconOutline,
    AttribListModal,
    EmptyState
  },
  props: ['product', 'optionsMain'],
  methods: {
    replaceImgWithExternalLink()
    {
        if(this.imgExternalInputValue.length === 0) return;
        this.clearImg();
        this.variant.image.ref = null;
        this.variant.image.url = this.imgExternalInputValue;
        this.imgPreviewSrc = this.imgExternalInputValue;
        this.imgExternalInputValue = "";
        this.showImgInputField = false;
    },
    toggleOtherSourcePhoto()
    {
        this.clearImg();
        this.variant.image.ref = null;
        this.variant.image.url = "";
        this.showImgInputField = !this.showImgInputField;
    },
    fixAmountOfPackages(){
        this.variant.amountOfPackages = Math.ceil(this.variant.amountOfPackages)
        if(this.variant.amountOfPackages < 1){
            this.variant.amountOfPackages = 1
        }
        if(this.variant.amountOfPackages > 100){
            this.variant.amountOfPackages = 100
        }
        this.variant.amountOfPackages = parseInt(this.variant.amountOfPackages)
    },
    fixQuantity(){
        if(this.variant.quantity < 0){
            this.variant.quantity = 0
        }
        if(this.variant.quantity > 999){
            this.variant.quantity = 999
        }
    },
    changeManufacturer(){
        if(this.variant.selfProduction){
            this.variant.manufacturer.name = "Jan-Art Sp. z o.o."
        }else{
            this.variant.manufacturer.name = ""
        }
    },
    addVariant(){
        this.variant.id = this.filenameGenerator(16, `v`) + `_p_${this.$route.query.id}`
        this.variant.dimensions.height = parseFloat(this.variant.dimensions.height);
        this.variant.dimensions.width = parseFloat(this.variant.dimensions.width);
        this.variant.dimensions.length = parseFloat(this.variant.dimensions.length);
        this.variant.pricesAndCosts.sellPrice = parseFloat(this.variant.pricesAndCosts.sellPrice);
        this.variant.pricesAndCosts.complaintCost = parseFloat(this.variant.pricesAndCosts.complaintCost);
        this.variant.pricesAndCosts.productionCost = parseFloat(this.variant.pricesAndCosts.productionCost);
        this.variant.weight.amount = parseFloat(this.variant.weight.amount);
        this.variant.amountOfPackages = parseInt(this.variant.amountOfPackages);
        if(this.$refs.mainImgUploader.files.length > 0){
            this.variant.imageToUpload = this.$refs.mainImgUploader.files[0]
            this.variant.imagePreview = this.imgPreviewSrc
        }
        this.variant.meta.createdDate = moment().toDate();
        this.variant.meta.lastModificationDate = moment().toDate();
        this.variant.meta.createdBy = `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`;
        this.variant.meta.userId = this.$store.state.userData.id;
        this.$emit("addVariant", this.variant);
        this.emitClose()
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    getCopyOfProduct()
    {
        let productCopyData = {
            active: this.product.active,
            name: this.product.name,
            model: this.product.model,
            manufacturer: this.product.manufacturer,
            description: this.product.description,
            selfProduction: this.product.selfProduction,
            amountOfPackages: this.product.amountOfPackages,
            attribs: {
                list: []
            },
            category: {
                id: this.product.category.id,
                name: this.product.category.name,
                ref: this.product.category.id.length > 0 ? db.collection("Categories").doc(this.product.category.id) : null,
            },
            dimensions: {
                unit: this.product.dimensions.unit,
                height: this.product.dimensions.height,
                width: this.product.dimensions.width,
                length: this.product.dimensions.length,
            },
            weight: {
                unit: this.product.weight.unit,
                amount: this.product.weight.amount
            },
            storage: {
                additionalInfo: this.product.storage.additionalInfo,
                location: this.product.storage.location

            }
            // attribs: JSON.parse(JSON.stringify(this.product.attribs)),

            }
            for(let i = 0; i < this.product.attribs.list.length; i++){
                let current = this.product.attribs.list[i]
                let preparedAttrib = {
                    id: current.id,
                    meta: current.meta,
                    name: current.name,
                    ref: db.collection('Attributes').doc(current.id),
                    parent: {
                        id: current.parent.id,
                        name: current.parent.name,
                        ref: db.collection('GroupsOfAttributes').doc(current.parent.id),
                        type: current.parent.type,
                    }
                }
                productCopyData.attribs.list.push(preparedAttrib)
            }
        return productCopyData;
    },
    checkForProduct(){
        if(this.product === null){
            this.checkForProduct()
        }else{
            let product = this.getCopyOfProduct();
            this.variant.active = product.active
            this.variant.attribs = product.attribs
            this.variant.category = product.category
            this.variant.description = product.description
            this.variant.manufacturer.name = typeof product.manufacturer === 'string' ? product.manufacturer : product.manufacturer.name
            this.variant.selfProduction = product.selfProduction
            this.variant.dimensions = product.dimensions
            this.variant.model = product.model
            this.variant.name = product.name
            this.variant.storage = product.storage
            this.variant.weight = product.weight
            this.variant.amountOfPackages = product.amountOfPackages
            for(let i=0; i<this.optionsMain.category.length; i++){
                this.options.category.push(this.optionsMain.category[i])
                if(this.variant.category.id.toString() === this.optionsMain.category[i].id.toString())
                {
                    this.setOptions.category = i;
                }
            }
            this.allToFixed2()
            this.loading = false
        };
    },
    pushToAttribList(buffer)
    {
        for(let i=0; i<buffer.length; i++)
        {
            this.variant.attribs.list.push(buffer[i]);
        }
    },
    closeModal()
    {
        this.showModal = false;
    },
    setCategory()
    {
        let newValue = this.options.category[this.setOptions.category];
        if(newValue === "")
        {
            this.variant.category.name = "";
            this.variant.category.ref = null;
            this.variant.category.id = "";
        }
        else
        {
            this.variant.category.name = newValue.name;
            this.variant.category.id = newValue.id.toString();
            this.variant.category.ref = db.collection("Categories").doc(newValue.id.toString());
        }
    },
    async getAdditionalOptions()
    {
        const categoryDocsResult = await db.collection("Categories").get();
        for(let i=0; i<categoryDocsResult.docs.length; i++)
        {
            this.options.category.push(categoryDocsResult.docs[i].data());
        }
    },
    dropFile(e)
    {
        if(e.dataTransfer.files.length>0)
        {
            if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
            {
                if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.dataTransfer.files[0]);
                    fileReader.addEventListener("load", () =>
                    {
                        this.imgPreviewSrc = fileReader.result;
                    })
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zdjęcie waży powyżej 2MB",
                        subheader: `Plik jest zbyt ciężki.`,
                        success: false
                    }); 
                }
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Plik nie jest zdjęciem.",
                    subheader: `Plik powinien być zdjęciem.`,
                    success: false
                });   
            }
        }
        
    },
    triggerImgClick()
    {
        this.$refs.mainImgUploader.click();
    },
    clearImg()
    {
        this.imgPreviewSrc = '';
        this.$refs.mainImgUploader.value = "";
    },
    allToFixed2()
    {
        this.variant.pricesAndCosts.complaintCost = 0;
        this.variant.pricesAndCosts.productionCost = 0;
        this.variant.pricesAndCosts.sellPrice = 0;
        this.variant.pricesAndCosts.complaintCost = this.variant.pricesAndCosts.complaintCost.toFixed(2);
        this.variant.pricesAndCosts.productionCost = this.variant.pricesAndCosts.productionCost.toFixed(2);
        this.variant.pricesAndCosts.sellPrice = this.variant.pricesAndCosts.sellPrice.toFixed(2);
    },
    checkProductionCost()
    {
        if(this.variant.pricesAndCosts.productionCost.length === 0)
        {
            this.variant.pricesAndCosts.productionCost = 0;
        }
        if(this.variant.pricesAndCosts.productionCost < 0)
        {
            this.variant.pricesAndCosts.productionCost = 0;
        }
        else
        {
            this.variant.pricesAndCosts.productionCost = parseFloat(this.variant.pricesAndCosts.productionCost);
        }
        this.variant.pricesAndCosts.productionCost = this.variant.pricesAndCosts.productionCost.toFixed(2);
    },
    checkSellPrice()
    {
        if(this.variant.pricesAndCosts.sellPrice.length === 0)
        {
            this.variant.pricesAndCosts.sellPrice = 0;
        }
        if(this.variant.pricesAndCosts.sellPrice < 0)
        {
            this.variant.pricesAndCosts.sellPrice = 0;
        }
        else
        {
            this.variant.pricesAndCosts.sellPrice = parseFloat(this.variant.pricesAndCosts.sellPrice);
        }
        this.variant.pricesAndCosts.sellPrice = this.variant.pricesAndCosts.sellPrice.toFixed(2);
    },
    checkComplaintCost()
    {
        if(this.variant.pricesAndCosts.complaintCost.length === 0)
        {
            this.variant.pricesAndCosts.complaintCost = 0;
        }
        if(this.variant.pricesAndCosts.complaintCost < 0)
        {
            this.variant.pricesAndCosts.complaintCost = 0;
        }
        else
        {
            this.variant.pricesAndCosts.complaintCost = parseFloat(this.variant.pricesAndCosts.complaintCost);
        }
        this.variant.pricesAndCosts.complaintCost = this.variant.pricesAndCosts.complaintCost.toFixed(2);
    },
    checkHeight()
    {
        if(this.variant.dimensions.height.length === 0)
        {
            this.variant.dimensions.height = 0;
        }
        if(this.variant.dimensions.height < 0)
        {
            this.variant.dimensions.height = 0;
        }
        else
        {
            this.variant.dimensions.height = parseFloat(this.variant.dimensions.height);
        }
        this.variant.dimensions.height = this.variant.dimensions.height.toFixed(2);
    },
    checkWidth()
    {
        if(this.variant.dimensions.width.length === 0)
        {
            this.variant.dimensions.width = 0;
        }
        if(this.variant.dimensions.width < 0)
        {
            this.variant.dimensions.width = 0;
        }
        else
        {
            this.variant.dimensions.width = parseFloat(this.variant.dimensions.width);
        }
        this.variant.dimensions.width = this.variant.dimensions.width.toFixed(2);
    },
    checkLength()
    {
        if(this.variant.dimensions.length.length === 0)
        {
            this.variant.dimensions.length = 0;
        }
        if(this.variant.dimensions.length < 0)
        {
            this.variant.dimensions.length = 0;
        }
        else
        {
            this.variant.dimensions.length = parseFloat(this.variant.dimensions.length);
        }
        this.variant.dimensions.length = this.variant.dimensions.length.toFixed(2);
    },
    checkWeight()
    {
        if(this.variant.weight.amount.length === 0)
        {
            this.variant.weight.amount = 0;
        }
        if(this.variant.weight.amount < 0)
        {
            this.variant.weight.amount = 0;
        }
        else
        {
            this.variant.weight.amount = parseFloat(this.variant.weight.amount);
        }
        this.variant.weight.amount = this.variant.weight.amount.toFixed(2);
    },
    setMainImage()
    {
        if(this.$refs.mainImgUploader.files.length>0)
        {
            if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
            {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                fileReader.addEventListener("load", () =>
                {
                    this.imgPreviewSrc = fileReader.result;
                })
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zdjęcie waży powyżej 2MB",
                    subheader: `Plik jest zbyt ciężki.`,
                    success: false
                });                    
            }
        }
    },
    filenameGenerator(length, prefix)
    {
        let result           = `${prefix}_`;
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    },
    sendBack()
    {
        this.$router.push("/dashboard/products")
    },
    changeStep(val)
    {
        switch(val)
        {
            case 1: 
                this.progress = 0;
                this.step = 1;
                break;
            case 2:
                if(this.validation1 === true)
                {
                    this.step = 2;
                    this.progress = 50;
                }
                break;
            case 3:
                this.step = 3;
                this.progress = 70;
                break;
            case 4:
                this.step = 4;
                this.progress = 100;
                setTimeout(() => {
                    this.addVariant();
                }, 1200);
        }
    }
},
computed:
{
    validation1()
    {
        if(this.variant.name.length>0)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
},
  mounted() {
    this.checkForProduct()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
