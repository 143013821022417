<template>
  <TransitionRoot as="template" v-if="product !== null" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          
          <div v-if="!loading" class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-11/12 sm:max-h-screen9/10 sm:p-6">
            <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->
            <div class="flex flex-col lg:flex-row justify-between lg:items-center">
            <div class="font-medium text-lg flex">
                <span>{{product.name}}</span>
                <span v-if="product.active" class="ml-4 select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 border border-green-500"> Aktywny </span>
                <span v-if="!product.active" class="ml-4 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 border border-red-500"> Nieaktywny </span>
            </div>
            <div class="mt-1 lg:mt-0">
                <div v-if="this.$route.path === '/dashboard/products/list/viewProduct'" @click.prevent="generateLabel(this.product, 'wariant')" class="transition ease-in-out duration-300 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Etykieta
                <BookmarkAltIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
                <div v-if="this.$route.path !== '/dashboard/products/list/viewProduct'" @click.prevent="this.$emit('editVariant', product.id)" class="transition cursor-pointer ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Edytuj
                <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
                <div v-if="product.link.href.length>0" @click.prevent="visitExternalSource" class="transition ease-in-out duration-300 ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Źródło
                <CursorClickIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
                <div @click.prevent="closeModal()" class="transition ease-in-out duration-300 ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Zamknij
                <XIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
            </div>
        </div>
        <div class="mt-4">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
        </div>
        <div class="mt-2 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Zdjęcie</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div class="group block w-1/4 aspect-w-10 aspect-h-7 rounded-lg  focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                            <img v-if="product.image.url.length>0" :src="product.image.url" class="object-cover pointer-events-none" />
                            <span class="select-none" v-if="product.image.url.length === 0">Nie wybrano zdjęcia</span>
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Model</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.model.length>0 ? product.model : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Producent</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span v-if="typeof product.manufacturer === 'string'">{{ product.manufacturer.length > 0 ? product.manufacturer : "--" }}</span>
                        <span v-else>{{ product.manufacturer.name.length > 0 ? product.manufacturer.name : "--" }}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">EAN</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{ product.ean.length>0 ? product.ean : "--" }}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">SKU</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.sku.length>0 ? product.sku : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Kategoria</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span>{{product.category.name.length>0 ? product.category.name : "--"}}</span>
                        <BanIcon v-if="!dataCorrectness.category.exists" class="ml-2 -mr-0.5 h-4 w-4 text-red-600" aria-hidden="true" />
                    </dd>
                    <span class="ml-2 text-xs text-gray-400 flex items-center" v-if="dataCorrectness.category.newData !== null"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Nazwa kategorii została zmieniona na: {{dataCorrectness.category.newData}}.</span>
                    <span class="ml-2 text-xs text-gray-400 flex items-center" v-if="!dataCorrectness.category.exists"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Ta kategoria została usunięta.</span>
                </div>
            </dl>
        </div>

        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Ilość paczek</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.amountOfPackages !== undefined && product.amountOfPackages > 0 ? product.amountOfPackages : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Opis wariantu</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.description.length>0 ? product.description : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Stan magazynowy</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Ilość</dt>
                    <dd :class="['mt-1 text-sm sm:mt-0 sm:col-span-2', product.quantity < 1 ? 'text-red-500' : (product.quantity > 0 && product.quantity < 4) ? 'text-yellow-500' :  'text-gray-900']">
                        <span>{{product.quantity}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Wymiary</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Długość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.length.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Wysokość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.height.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Szerokość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.width.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Waga</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.weight.amount.toFixed(2)}} {{product.weight.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Koszty</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Koszty produkcji</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.productionCost.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Sugerowana cena końcowa</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.sellPrice.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Koszt reklamacji</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.complaintCost.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Stawka VAT</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.taxRate}}%</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div :class="['mt-12', product.attribs.list.length === 0 ? '' : 'w-full border-b pb-2']">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
        </div>
        <div :class="[product.attribs.list.length === 0 ? 'mt-2' : 'mt-5']">
            <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                <li v-for="(attrib) in product.attribs.list" :key="attrib.id" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <!-- <div :class="[ 'bg-yellow-200 flex-shrink-0 flex items-center justify-center w-16 p-0.5 rounded-md text-black text-sm font-medium select-none']">
                                {{attrib.parent.name.substring(0,3).toUpperCase()}}
                            </div> -->
                            <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm">{{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="transition ease-in-out duration-300 text-xs opacity-40 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                ID: {{ attrib.id }}
                            </p>
                        </div>
                    </div>
                    <span class="text-xs" v-if="!attrib.exists">Ten atrybut został usunięty z <router-link class="transition ease-in-out duration-300 text-blue-500 hover:text-blue-400" to="/dashboard/products/attributes" target="_blank" >grup atrybutów</router-link>.</span>
                </li>
            </ul>
            <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
        </div>
        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Lokalizacja</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Położenie</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.location.length>0 ? product.storage.location : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Dodatkowe informacje</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.additionalInfo.length>0 ? product.storage.additionalInfo : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Pola dodatkowe</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 1</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField1.length > 0 ? product.extraField1 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 2</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField2.length > 0 ? product.extraField2 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 3</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField3.length > 0 ? product.extraField3 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Identyfikatory</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">ID wariantu</dt>
                    <dd class="mt-1 text-xs sm:text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.id !== undefined > 0 ? product.id : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Baselinker ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.baselinkerId !== undefined ? product.baselinkerId.toString() : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Apilo ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.apiloId !== undefined ? product.apiloId.toString() : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Bol.com ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.integratorIds.bol != null ? product.integratorIds.bol.toString().length > 0 ? product.integratorIds.bol.toString() : '---' : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Wayfair.com ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.integratorIds.wayfair !== undefined ? product.integratorIds.wayfair.toString().length > 0 ? product.integratorIds.wayfair.toString() : '---' : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="flex flex-col lg:flex-row justify-between mt-4">
            <span class="text-xs select-none text-gray-400">Wariant został stworzony przez {{product.meta.createdBy}} <span class="text-gray-500">{{creationDate}}</span>.</span>
            <span class="text-xs mt-2 sm:mt-0 select-none text-gray-400">Ostatnia aktualizacja wariantu <span class="text-gray-500">{{modificationDate}}.</span></span>
        </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PencilIcon,BookmarkAltIcon, CursorClickIcon, BanIcon, InformationCircleIcon, XIcon } from '@heroicons/vue/outline'
import { HomeIcon } from '@heroicons/vue/solid'
import moment from 'moment';
import {db} from "@/firebase/gfbconf.js";
import EmptyState from '../components/EmptyState.vue';

export default {
    data()
        {
        return {
            loading: false,
            dataCorrectness: {
                    category: {
                        exists: true,
                        newData: null,
                    }
                },
        }
    },
  components: {
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, EmptyState,PencilIcon,BookmarkAltIcon,CursorClickIcon,BanIcon,InformationCircleIcon,HomeIcon, XIcon
  },
  props: ["product"],
  methods: {
    generateLabel(labelProduct, type) {
        var docDefinition = {
            pageSize: 'A6',
            pageMargins: [10, 35, 10, 35 ],

            content: [
                {text: labelProduct.name, fontSize: 14, bold:true, alignment: 'center'},
                {text: `(${type})`, fontSize: 8, bold:true, alignment: 'center'},
                {text: '', margin: [0,4,0,4]},
                {text: [{text: 'Model: ', bold:true}, labelProduct.model.length > 0 ? labelProduct.model : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                {text: '', margin: [0,1,0,1]},
                {text: [{text: 'Producent: ', bold:true}, typeof labelProduct.manufacturer === "string" ? (labelProduct.manufacturer.length > 0 ? labelProduct.manufacturer : "BRAK DANYCH") : (labelProduct.manufacturer.name.length ? labelProduct.manufacturer.name : "BRAK DANYCH") ], fontSize: 10, alignment: 'center'},
                {text: '', margin: [0,1,0,1]},
                {text: [{text: 'Kategoria: ', bold:true}, labelProduct.category.name.length > 0 ? labelProduct.category.name : "BRAK KATEGORII"], fontSize: 10, alignment: 'center'},
                {text: '', margin: [0,8,0,8]},
                {text: 'Wymiary: ', bold:true, fontSize: 10, alignment: 'center'},
                {text: '', margin: [0,1,0,1]},
                {
                    table: {
                    widths: ['50%','50%'],
                    body: [
                        [
                            {text: [{text: 'Długość: ', bold:true}, labelProduct.dimensions.length > 0 ? `${labelProduct.dimensions.length.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                            {text: [{text: 'Wysokość: ', bold:true},labelProduct.dimensions.height > 0 ? `${labelProduct.dimensions.height.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                        ]
                    ]}
                },
                {
                    table: {
                    widths: ['50%','50%'],
                    body: [
                        [
                            {text: [{text: 'Szerokość: ', bold:true}, labelProduct.dimensions.width > 0 ? `${labelProduct.dimensions.width.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                            {text: [{text: 'Waga: ', bold:true}, labelProduct.weight.amount > 0 ? `${labelProduct.weight.amount.toFixed(2)} ${labelProduct.weight.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                        ]
                    ]}
                },
                {text: '', margin: [0,8,0,8]},
                {text: 'Lokalizacja: ', bold:true, fontSize: 10, alignment: 'center'},
                {text: '', margin: [0,1,0,1]},
                {text: labelProduct.storage.location.length > 0 ? labelProduct.storage.location : "BRAK DANYCH O LOKALIZACJI", fontSize: 12, alignment: 'center'},
                {text: '', margin: [0,1,0,1]},
                {text:  labelProduct.storage.additionalInfo.length > 0 ? `(${labelProduct.storage.additionalInfo})` : "BRAK DODATKOWYCH INFORMACJI", fontSize: 8, alignment: 'center'},
                {text: '', margin: [0,10,0,10]},
                {
                    table: {
                    widths: ['50%','50%'],
                    body: [
                        [
                            {text: [{text: 'SKU: ', bold:true}, labelProduct.sku.length > 0 ? labelProduct.sku : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, true, false]},
                            {text: [{text: 'EAN: ', bold:true}, labelProduct.ean.length > 0 ? labelProduct.ean : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                        ]
                    ]}
                },
                {text: '', margin: [0,10,0,10]},
                { qr: labelProduct.id, fit: '115', version: 3,  alignment: 'center' },
            ],
        };
        pdfMake.createPdf(docDefinition).download(`Etykieta_${labelProduct.name.replace(/\s/g, '_')}.pdf`);
    },
    closeModal()
    {
        this.$emit("close");
    },
    async checkForProduct(){
        if(this.product === null){
            this.checkForProduct()
        } else {
            await this.isItActualWithDatabase();
            this.loading = false
        };
    },
    async checkIfActual(ref,actualValue)
            {
                let info = {}
                let result = await ref.get();
                info.exists = result.exists;

                if(result.exists === true)
                {
                    result = result.data()
                    if(this.isSame(result.name,actualValue) === true)
                    {
                        info.newData = null;
                    }
                    else
                    {
                        info.newData = result.name;
                    }
                }
                else
                {
                    info.newData = null;
                }
                return info;
    },
    isSame(a,b) {
        //czadowa funkcja bracie
        return a === b
    },
    async isItActualWithDatabase()
    {
        let dbStatus = {}
        // Check category
        let count = 0;
        if(this.product.category.ref !== null)
        {
            count++;
            dbStatus.category = await this.checkIfActual(this.product.category.ref,this.product.category.name)
        }
        
        for(let i=0; i<this.product.attribs.list.length;i++)
        {
            count++;
            let currentAttrib = this.product.attribs.list[i];
            let attribRefResult = await currentAttrib.ref.get();
            
            currentAttrib.exists = attribRefResult.exists;
        }
        
        if(count>0)
        {
            this.dataCorrectness = dbStatus;
        }
        
    },
    visitExternalSource() {
        if(this.product.link.href.length === 0) return;
        window.open(this.product.link.href, '_blank');
    },
},
computed:
{
    creationDate()
    {
        moment.locale('pl');
        if(this.product.meta.createdDate.seconds !== undefined){
            return moment.unix(this.product.meta.createdDate.seconds).format('LLL');
        }else{
            return moment(this.product.meta.createdDate).format('LLL');
        }
    },
    modificationDate()
    {
        if(this.product.meta.lastModificationDate.seconds !== undefined){
            return moment.unix(this.product.meta.lastModificationDate.seconds).format("LLL");
        }else{
            return moment(this.product.meta.lastModificationDate).format('LLL');
        }
    }
},
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>