<template>
    <div v-if="!loading">
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/products" class="transition ease-in-out duration-300  ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/products/list" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Lista produktów</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">{{product.name}}</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/products/list" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
        
        <div class="flex flex-col lg:flex-row justify-between lg:items-center">
            <div class="font-medium text-lg flex">
                <span>{{product.name}}</span>
                <span v-if="product.active" class="ml-4 select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 border border-green-500"> Aktywny </span>
                <span v-if="!product.active" class="ml-4 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 border border-red-500"> Nieaktywny </span>
            </div>
            <div class="mt-1 lg:mt-0">
                <div @click.prevent="generateLabel(this.product, 'główny')" class="transition ease-in-out duration-300 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Etykieta
                <BookmarkAltIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
                <router-link :to="`/dashboard/products/list/editProduct?id=${product.id}`" class="transition ease-in-out duration-300 ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Edytuj
                <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </router-link>
                <div v-if="product.link.href.length>0" @click.prevent="visitExternalSource" class="transition ease-in-out duration-300 ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Źródło
                <CursorClickIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
            </div>
        </div>
        <div class="mt-4">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
        </div>
        <div class="mt-2 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Zdjęcie</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div class="group block w-1/4 aspect-w-10 aspect-h-7 rounded-lg  focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                            <img v-if="product.image.url.length>0" :src="product.image.url" class="object-cover pointer-events-none" />
                            <span class="select-none" v-if="product.image.url.length === 0">Nie wybrano zdjęcia</span>
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Model</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.model.length>0 ? product.model : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Producent</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span>{{typeof product.manufacturer === 'string' ? product.manufacturer.length>0 ? product.manufacturer : '---' : product.manufacturer.name.length>0 ? product.manufacturer.name : '---'}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">EAN</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.ean.length>0 ? product.ean : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">SKU</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.sku.length>0 ? product.sku : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Kategoria</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span>{{product.category.name.length>0 ? product.category.name : "--"}}</span>
                        <BanIcon v-if="!dataCorrectness.category.exists" class="ml-2 -mr-0.5 h-4 w-4 text-red-600" aria-hidden="true" />
                    </dd>
                    <span class="ml-2 text-xs text-gray-400 flex items-center" v-if="dataCorrectness.category.newData !== null"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Nazwa kategorii została zmieniona na: {{dataCorrectness.category.newData}}.</span>
                    <span class="ml-2 text-xs text-gray-400 flex items-center" v-if="!dataCorrectness.category.exists"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Ta kategoria została usunięta.</span>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Ilość paczek</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.amountOfPackages !== undefined && product.amountOfPackages > 0 ? product.amountOfPackages : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Opis produktu</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.description.length>0 ? product.description : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Wymiary</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Długość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.length.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Wysokość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.height.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Szerokość</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.dimensions.width.toFixed(2)}} {{product.dimensions.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Waga</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.weight.amount.toFixed(2)}} {{product.weight.unit}}</span>
                    </dd>
                </div>
            </dl>
        </div>


        <div :class="['mt-12', product.attribs.list.length === 0 ? '' : 'w-full border-b pb-2']">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
        </div>
        <div :class="[product.attribs.list.length === 0 ? 'mt-2' : 'mt-5']">
            <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                <li v-for="(attrib) in product.attribs.list" :key="attrib.id" class="py-4">
                    <div v-if="attrib !== undefined" class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <!-- <div :class="[ 'bg-yellow-200 flex-shrink-0 flex items-center justify-center w-16 p-0.5 rounded-md text-black text-sm font-medium select-none']">
                                {{attrib.parent.name.substring(0,3).toUpperCase()}}
                            </div> -->
                            <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm">{{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="transition ease-in-out duration-300 text-xs opacity-40 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                ID: {{ attrib.id }}
                            </p>
                        </div>
                    </div>
                    <span class="text-xs" v-if="!attrib.exists">Ten atrybut został usunięty z <router-link class="transition ease-in-out duration-300 text-blue-500 hover:text-blue-400" to="/dashboard/products/attributes" target="_blank" >grup atrybutów</router-link>.</span>
                </li>
            </ul>
            <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
        </div>
        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Lokalizacja</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Położenie</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.location.length>0 ? product.storage.location : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Dodatkowe informacje</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.additionalInfo.length>0 ? product.storage.additionalInfo : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-12 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Pola dodatkowe</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 1</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField1.length > 0 ? product.extraField1 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 2</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField2.length > 0 ? product.extraField2 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Pole dodatkowe 3</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField3.length > 0 ? product.extraField3 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div :class="['mt-12', Object.keys(product.variants).length === 0 ? '' : 'w-full border-b pb-2']">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Warianty</p>
        </div>
        <div class="mb-12">
            <div :class="[Object.keys(product.variants).length === 0 ? 'mt-2': 'mt-6']">
                <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="Object.keys(product.variants).length>0">
                    <li v-for="(variant,variantIndx) in product.variants" :key="variantIndx" :class="[variantIndx !== Object.keys(product.variants)[Object.keys(product.variants).length -1] ? 'py-4' : 'pt-4 pb-2']">
                        <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                                <img v-if="variant.image.url.length > 0 || variant.imagePreview !== undefined" class="h-10 w-10 rounded-full" :src="variant.image.url.length > 0 ? variant.image.url : variant.imagePreview" alt="" />
                                <div v-else class="h-10 w-10 rounded-full text-white bg-yellow-400 flex justify-center items-center text-sm">{{variant.name.substring(0,3).toUpperCase()}}</div>
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    {{ variant.name }} 
                                </p>
                                <p class="text-sm text-gray-500 truncate">
                                    ID: {{ variantIndx }}
                                </p>
                            </div>
                            <div class="hidden sm:block w-16">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        Ilość:
                                    </p>
                                    <p :class="['text-sm truncate', variant.quantity < 1 ? 'text-red-500' : (variant.quantity > 0 && variant.quantity < 4) ? 'text-yellow-500' :  'text-gray-500']">
                                        {{variant.quantity}} szt.
                                    </p>
                                </div>
                            <div class="flex">
                                <button @click.prevent="generateLabel(variant, 'wariant')" class="transition ease-in-out duration-300 mr-2 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-blue-50 hover:text-blue-500 hover:border-blue-200"> <span class="hidden sm:block">Etykieta</span> <BookmarkAltIcon class="sm:ml-2 -mr-0.5 -ml-0.5 h-4 w-4" aria-hidden="true" /></button>
                                <button @click.prevent="openViewVariant(variant)" class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-blue-50 hover:text-blue-500 hover:border-blue-200"> Zobacz </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <EmptyState v-if="Object.keys(product.variants).length < 1"></EmptyState>
        </div>

        <div class="mt-16 pb-2">
            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Identyfikatory</p>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">ID produktu</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.id !== undefined > 0 ? product.id : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Baselinker ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.baselinkerId !== undefined ? product.baselinkerId.toString() : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-400 select-none">Apilo ID</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.apiloId !== undefined ? product.apiloId.toString() : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="flex flex-col lg:flex-row justify-between mt-4">
            <span class="text-xs select-none text-gray-400">Produkt został stworzony przez {{product.meta.createdBy}} <span class="text-gray-500">{{creationDate}}</span>.</span>
            <span class="mt-2 sm:mt-0 text-xs select-none text-gray-400">Ostatnia aktualizacja produktu <span class="text-gray-500">{{modificationDate}}.</span></span>
        </div>
        <VariantViewModal v-if="viewVariantModal.open" :product="viewVariantModal.variant" @close="closeViewVariant"></VariantViewModal>
    </div>
</template>

<script>
    import { PencilIcon, CursorClickIcon, BanIcon, InformationCircleIcon, BookmarkAltIcon } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import moment from 'moment';
    import {db, firebase} from "@/firebase/gfbconf.js";
    import axios from 'axios';
    import EmptyState from '../components/EmptyState.vue';
    import VariantViewModal from '@/components/VariantViewModal.vue';
    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    export default {
        name: "ProductViewmode",
        data()
        {
            return {
                loading: true,
                dataCorrectness: {
                    category: {
                        exists: true,
                        newData: null
                    }
                },
                viewVariantModal: {
                    open: false,
                    variant: null,
                },
            }
        },
        components: {
            EmptyState,PencilIcon,BookmarkAltIcon,CursorClickIcon,BanIcon,InformationCircleIcon,HomeIcon,VariantViewModal
        },
        async created()
        {
            await this.getProduct();
        },
        computed:
        {
            creationDate()
            {
                moment.locale('pl');
                return moment.unix(this.product.meta.createdDate.seconds).format('LLL');
            },
            modificationDate()
            {
                return moment.unix(this.product.meta.lastModificationDate.seconds).format("LLL");
            }
        },
        methods:
        {
            generateLabel(labelProduct, type) {
                var docDefinition = {
                    pageSize: 'A6',
                    pageMargins: [10, 35, 10, 35 ],

                    content: [
                        {text: labelProduct.name, fontSize: 14, bold:true, alignment: 'center'},
                        {text: `(${type})`, fontSize: 8, bold:true, alignment: 'center'},
                        {text: '', margin: [0,4,0,4]},
                        {text: [{text: 'Model: ', bold:true}, labelProduct.model.length > 0 ? labelProduct.model : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Producent: ', bold:true}, labelProduct.manufacturer.length > 0 ? labelProduct.manufacturer : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Kategoria: ', bold:true}, labelProduct.category.name.length > 0 ? labelProduct.category.name : "BRAK KATEGORII"], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,8,0,8]},
                        {text: 'Wymiary: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'Długość: ', bold:true}, labelProduct.dimensions.length > 0 ? `${labelProduct.dimensions.length.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Wysokość: ', bold:true},labelProduct.dimensions.height > 0 ? `${labelProduct.dimensions.height.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'Szerokość: ', bold:true}, labelProduct.dimensions.width > 0 ? `${labelProduct.dimensions.width.toFixed(2)} ${labelProduct.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Waga: ', bold:true}, labelProduct.weight.amount > 0 ? `${labelProduct.weight.amount.toFixed(2)} ${labelProduct.weight.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {text: '', margin: [0,8,0,8]},
                        {text: 'Lokalizacja: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: labelProduct.storage.location.length > 0 ? labelProduct.storage.location : "BRAK DANYCH O LOKALIZACJI", fontSize: 12, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text:  labelProduct.storage.additionalInfo.length > 0 ? `(${labelProduct.storage.additionalInfo})` : "BRAK DODATKOWYCH INFORMACJI", fontSize: 8, alignment: 'center'},
                        {text: '', margin: [0,10,0,10]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'SKU: ', bold:true}, labelProduct.sku.length > 0 ? labelProduct.sku : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, true, false]},
                                    {text: [{text: 'EAN: ', bold:true}, labelProduct.ean.length > 0 ? labelProduct.ean : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {text: '', margin: [0,10,0,10]},
                        { qr: labelProduct.id, fit: '115', version: 3,  alignment: 'center' },
                    ],
                };
                pdfMake.createPdf(docDefinition).download(`Etykieta_${labelProduct.name.replace(/\s/g, '_')}.pdf`);
            },
            closeViewVariant()
            {
                this.viewVariantModal = {
                    open: false,
                    variant: null
                }
            },
            openViewVariant(variant){
                this.viewVariantModal = {
                    open: true,
                    variant: variant
                }
            },
            async checkIfActual(ref,actualValue)
            {
                let info = {}
                let result = await ref.get();
                info.exists = result.exists;

                if(result.exists === true)
                {
                    result = result.data()
                    if(this.isSame(result.name,actualValue) === true)
                    {
                        info.newData = null;
                    }
                    else
                    {
                        info.newData = result.name;
                    }
                }
                else
                {
                    info.newData = null;
                }
                return info;
            },
            isSame(a,b)
            {
                return a === b
            },
            async isItActualWithDatabase()
            {
                let dbStatus = {}
                // Check category
                let count = 0;
                if(this.product.category.ref !== null)
                {
                    count++;
                    dbStatus.category = await this.checkIfActual(this.product.category.ref,this.product.category.name)
                }
                
                for(let i=0; i<this.product.attribs.list.length;i++)
                {
                    count++;
                    let currentAttrib = this.product.attribs.list[i];
                    let attribRefResult = await currentAttrib.ref.get();
                    currentAttrib.exists = attribRefResult.exists;
                }
                
                if(count>0)
                {
                    // this.dataCorrectness = dbStatus;
                }
                
            },
            visitExternalSource()
            {
                if(this.product.link.href.length === 0) return;
                window.open(this.product.link.href, '_blank');
            },
            async getProduct()
            {
                if(this.$route.query.id === undefined || this.$route.query.id === null || this.$route.query.id.length === 0)
                {
                    this.$store.commit('setNotification',{
                            show: true,
                            head: "Błąd",
                            subheader: `Nie udało się pobrać produktu.`,
                            success: false
                    });
                    this.$router.push("/dashboard/products/list");
                }
                else
                {
                    try{
                        const queryResult = await db.collection("Products").doc(this.$route.query.id).get();
                        if(!queryResult.exists)
                        {
                            throw 'Product nie istnieje!';
                        }
                        else
                        {
                            this.product = queryResult.data();
                            document.title = `${this.product.name} - Jan-Art Sp. z o.o.`;
                            await this.isItActualWithDatabase();
                            this.loading = false;
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                                show: true,
                                head: "Błąd",
                                subheader: `Nie udało się pobrać produktu.`,
                                success: false
                        });
                        this.$router.push("/dashboard/products/list");
                    }
                    
                }
                // this.$store.state.userData.system.ref.collection("Products").doc("")
            }
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>